import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        //padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: '800px',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    margin: {
        margin: `${theme.spacing(1)}px auto`,
    },
    textField: {
        //width: 200,
    },
    button: {
        margin: `${theme.spacing(1)}px 0`,
    },
});


class LoginForm extends Component{
    constructor(props){
        super();
        this.state = {
            showPassword: false,
            values: {
                password: '',
                email: ''
            }
        };
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleClickShowPassword(){
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    handleMouseDownPassword(event) {
        event.preventDefault();
    }

    handleChange(event) {
        const obj = this.state.values;
        obj[event.target.name] = event.target.value;
        console.log(obj);
        this.setState({
            values: obj
        }, this.props.handleFormChange(event));
    }

    render(){
        this.props.setTitle("Авторизация");

        if (this.props.isAuthenticated) {
            return <Redirect to='/' />;
        }

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>

                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            name="email"
                            value={this.state.values.email}
                            onChange={this.handleChange}
                            labelWidth={60}
                        />
                    </FormControl>

                    <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="password">Password</InputLabel>

                        <OutlinedInput
                            id="password"
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.values.password}
                            onChange={this.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />

                    </FormControl>
                    <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.props.handleUserFormSubmit}
                        >
                            Send
                        </Button>
                    </FormControl>
                </Paper>
            </div>
        );
    }
};

export default withStyles(styles)(LoginForm);
