import React, {Component} from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


const styles = theme => ({
    table: {

    },
    '@media (max-width: 960px)': {
        cell:{
            padding: '8px'
        },
        addTd: {
            padding: '3px'
        }
    },
    '@media (max-width: 700px)': {
        cell:{
            padding: '8px',
            fontSize: '11px'
        }
    }
});

const toFixedVals = ['average_discount', 'shipment_plan', 'payment_plan', 'shipment_plan_montly', 'payment_plan_montly'];
const tSepVals = [
    'payment_amount',
    'profit',
    'orders_amount',
    'SKU_amount',
    'orders_total',
    'underloading',
    'shipment_refund',
    'shipment_amount',
    'refund_amount',
    'shipment_plan',
    'payment_plan',
    'shipment_plan_montly',
    'payment_plan_montly'
];

const ruNames = {
    shipment_refund: "Отгрузка",
    markup: "Наценка %",
    payment_amount: "Платеж",
    profit: "Доход",
    orders_amount: "Сумма Заказов",
    SKU_amount: "Sku",
    average_discount: "Ср. скидка %",
    orders_total: "Кол-во Заказов",
    underloading: "Недогруз",
    plan: "План %",
    shipment_amount: "Факт. Отгр.",
    refund_amount: "Возврат",
    shipment_plan: "ПланДниОтгр",
    payment_plan: "ПланДниПл",
    shipment_plan_montly: "ПланМесОтгр",
    payment_plan_montly: "ПланМесПл"
};

const groups = ['orders', 'shipment', 'payment', 'underloading', 'plan', 'planMonthly'];
const t_groups = ['orders', 'shipment', 'payment','underloadin', 'plan', 'planMonthly'];

const names_map = {
    "ОБК": "ОБК",
    "ХАММЕР": "ХМР",
    "Триколор М": "ТКЛ",
    "Техсервис": "ТХС",
    "total": "Итого"
}


const order = {
    orders: [
        {
            name: "orders_amount",
            group: "orders_main"
        },
        // {
        //     name: "SKU_amount",
        //     group: "orders_second"
        // },
        // {
        //     name: "average_discount",
        //     group: "orders_second"
        // },
        {
            name: "orders_total",
            group: "orders_second"
        }
    ],
    shipment: [
        {
            name: "shipment_refund",
            group: "shipment_main"
        },
        {
            name: "shipment_amount",
            group: "shipment_second"
        },
        {
            name: "refund_amount",
            group: "shipment_second"
        },
        {
            name: "markup",
            group: "shipment_second"
        }
    ],
    payment: [
        {
            name: "payment_amount",
            group: "payment_main"
        },
        {
            name: "profit",
            group: "payment_second"
        }
    ],
    underloading: [
        {
            name: "underloading",
            group: "underloading"
        },
    ],
    plan: [
        {
            name: "shipment_plan",
            group: "plan_main"
        },
        {
            name: "payment_plan",
            group: "plan_second"
        }
    ],
    planMonthly: [
        {
            name: "shipment_plan_montly",
            group: "planMonthly_main"
        },
        {
            name: "payment_plan_montly",
            group: "planMonthly_second"
        }
    ]    
};

class TSimpleTable extends Component {
    constructor(props) {
        super();
        this.state = {
            data: props.data,
            columns: props.columns,
            folded: {
                shipment: true,
                payment: true,
                orders: true,
                plan: true,
                planMonthly: true
            }
        };
        this.toggleGroup = this.toggleGroup.bind(this);
    }

    toggleGroup(g_name){
        if (t_groups.includes(g_name)) {
            const folded = this.state.folded;
            folded[g_name] = !folded[g_name];
            this.setState({folded: folded});
        }

        //console.log(this.state.folded);
    }

    render() {
        const { classes } = this.props;

        const head = this.state.columns.map((item, index) => {
            return (
                <TableCell
                    className={classes.cell}
                    key={index}
                    align="center"
                    style={{'cursor': 'pointer'}}
                    id={this.state.data['company_id'][item]}
                    onClick={this.props.handleSelect}
                >{names_map[item]}</TableCell>
            );
        });

        const b_grouped = [];
        groups.forEach((group, index) => {
            const group_items = order[group];

            if (group === 'underloading') {
                return;
            }

            group_items.forEach((item, idx)=>{
                const row_ = [];
                const name = item['name'];
                row_.push({
                    name: ruNames[name],
                    key: `i0_${name}`,
                    group: group,
                    in_group: idx
                });
                this.state.columns.forEach((c, ind) => {
                    let value = this.state.data[name][c];

                    if (toFixedVals.includes(name)){
                        value = value.toFixed(2);
                    }

                    if (tSepVals.includes(name)){
                        value = tSep(value);
                    }

                    row_.push({
                        name: value,
                        key: ind
                    });
                });
                b_grouped.push(row_);
            });
        });

        //console.log(b_grouped);

        const b = b_grouped.map((group, key) => {
            let groupName = null;
            let in_group = null;
            const cells = group.map((cell, k)=>{
                if (cell.group !== undefined) {
                    groupName = cell.group;
                    in_group = cell.in_group;

                    let icon = (this.state.folded[groupName] === true) ? <ArrowRightIcon /> : <ArrowDropUpIcon color={'disabled'}/>;

                    if (!t_groups.includes(groupName)) {
                        icon = ' ';
                    }

                    if ((in_group === 0) && (this.state.folded[groupName] === false)){
                        icon = <ArrowDropDownIcon />;
                    }

                    return (
                        <TableCell
                            className={classes.cell}
                            key={cell.key}
                            style={{'cursor': 'pointer', width: '30%', paddingRight: '6%'}}
                            onClick={() => {this.toggleGroup(cell.group)}}
                            align="right">
                            {cell.name} {icon}
                        </TableCell>
                    );
                } else {
                    return (
                        <TableCell className={classes.cell} key={cell.key} align="right">{cell.name}</TableCell>
                    );
                }

            });



            if ((in_group !== 0) && (this.state.folded[groupName] === true )){
                return null;
            } else {
                let bc = 'inherit';
                if (t_groups.includes(groupName)) {
                    const isOdd = Math.abs(t_groups.indexOf(groupName) % 2) == 1;
                    if (!isOdd) {
                        bc = "#3f51b514";
                    }
                }

                //if (t_groups.includes(groupName) && (Math.abs(key % 2) == 1) && )

                //const bc = (t_groups.includes(groupName) && (Math.abs(key % 2) == 1)) ? "#3f51b514": "inherit";
                return (
                    <TableRow style={{backgroundColor: bc}} key={key}>
                        {cells}
                    </TableRow>
                );
            }
        });

        return (
            <Table className={classes.table} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cell} key={'head0'} style={{fontWeight: 100}} align="center"></TableCell>
                        {head}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {b}
                </TableBody>
            </Table>
        );
    }
}

function tSep(num) {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numParts.join(".");
}

export default withStyles(styles)(TSimpleTable);