import React, {Component} from 'react';
import { Route, Switch } from 'react-router-dom';
import ApiService from './Components/services/api-service';
import {Header} from './Layouts';
import {TotalStat, LoginForm, Logout, Help} from './Pages';

class App extends Component {
    constructor(props){
        super();
        this.state = {
            userData: {
                "email": "",
                "name": ""
            },
            loginData: {
                email: '',
                password: ''
            },
            isAuthenticated: false,
            apiService: new ApiService(),
            title: '',
            loading: false
        };
        this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.clearCache = this.clearCache.bind(this);
    }

    componentDidMount() {
        if (window.localStorage.getItem('statAuthToken')) {
            this.state.apiService.getStatus()
                .then((res) => {
                    if (res.status === 'success'){
                        this.setState({
                            isAuthenticated: true,
                            userData: res.data
                        })
                    }
                })
                .catch((err) => {console.log(err)});
        }
    }

    handleUserFormSubmit(event) {
        event.preventDefault();
        let data = {
            email: this.state.loginData.email,
            password: this.state.loginData.password,
        };

        this.state.apiService.login(data)
            .then((res) => {
                console.log('res', res);
                window.localStorage.setItem('statAuthToken', res.auth_token);
                this.setState({ isAuthenticated: true, });
            })
            .catch((e) => {
                console.log(e);
                window.alert('Login incorrect');
            })
    }

    handleFormChange(event) {
        const obj = this.state.loginData;
        obj[event.target.name] = event.target.value;
        this.setState({loginData: obj}, console.log(this.state));
    }

    logoutUser() {
        console.log('logout');
        window.localStorage.setItem('statAuthToken', 'ddd');
        this.setState({
            isAuthenticated: false,
            loginData: {
                email: '',
                password: ''
            },
            userData: {
                "email": "",
                "name": ""
            }
        });
    }

    clearCache() {
        console.log('clear cache');
        this.setState({
            loading: true
        }, console.log(`loading: ${this.state.loading}`));

        this.state.apiService.clearCache()
            .then((res) => {
                console.log(`status: ${res.status}`);
                if (res.status === 'success'){
                    console.log("set state");
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                })            
            });
    }

    setTitle(title){
        const currentTitle = this.state.title;
        if (currentTitle !== title ) {
            this.setState({
                title: title
            })
        }
    }


    render() {
        const loading = this.state.loading;

        if (loading) {
            return (
                <React.Fragment>Loading...</React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Header
                        title={this.state.title}
                        isAuth={this.state.isAuthenticated}
                        logoutHandler={this.logoutUser}
                        clearCacheHandler={this.clearCache}/>
                    <Switch>
                        <Route exact path='/login' render={() => (
                            <LoginForm
                                formType={'Login'}
                                formData={this.state.loginData}
                                handleUserFormSubmit={this.handleUserFormSubmit}
                                handleFormChange={this.handleFormChange}
                                isAuthenticated={this.state.isAuthenticated}
                                setTitle={this.setTitle}
                            />
                        )} />
                        <Route exact path='/logout' render={() => (
                            <Logout
                                logoutUser={this.logoutUser}
                                isAuthenticated={this.state.isAuthenticated}
                            />
                        )} />

                        <Route exact path='/' render={() => (
                            <TotalStat
                                setTitle={this.setTitle}
                                isAuthenticated={this.state.isAuthenticated} />
                        )} />

                        <Route exact path='/help' render={() => (
                            <Help
                                setTitle={this.setTitle}
                            />
                        )} />

                        <TotalStat/>
                    </Switch>
                </React.Fragment>
            );
        }
    }
}

export default App;

