import React from 'react';
import SortSharpIcon from '@material-ui/icons/SortSharp';

const SortLabel = (props) => {
    const color = (props.active) ? 'primary' : 'disabled';
    return (
        <React.Fragment>
            <SortSharpIcon
                onClick={props.onClick}
                style={{cursor: 'pointer', ...props.style}}
                color={color}
                fontSize={'small'} />
        </React.Fragment>
    );
};

SortLabel.defaultProps = {
    active: false
};

export default SortLabel;
