import React, {Component} from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';


const styles = theme => ({
    table: {

    },
});

class SimpleTable extends Component {
    constructor(props) {
        super();
        this.state = {
            data: props.data
        }
    }

    render(){
        const { classes } = this.props;

        const cm = this.state.data.filter((el, index)=>{return el.company !== 'total'});
        const total = this.state.data.filter((el, index)=>{return el.company === 'total'})[0];

        const b = cm.map((row, index) => {
            return (
                <TableRow key={index}>
                    <TableCell
                        align="center"
                        style={{'cursor': 'pointer'}}
                        id={row.company_id}
                        onClick={this.props.handleSelect}>
                        {row.company}
                    </TableCell>

                    {/*отгрузка, наценка */}
                    <TableCell align="center">{tSep(row.shipment_amount - row.refund_amount)}</TableCell>
                    <TableCell align="center">{row.markup}</TableCell>

                    {/*Платеж, Доход */}
                    <TableCell align="center">{tSep(row.payment_amount)}</TableCell>
                    <TableCell align="center">{tSep(row.profit)}</TableCell>

                    {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                    <TableCell align="center">{tSep(row.orders_amount)}</TableCell>
                    <TableCell align="center">{tSep(row.SKU_amount)}</TableCell>
                    <TableCell align="center">{row.average_discount.toFixed(2)}</TableCell>
                    <TableCell align="center">{tSep(row.orders_total)}</TableCell>

                    {/* Недогруз */}
                    <TableCell align="center">{tSep(row.underloading)}</TableCell>

                    {/* План */}
                    <TableCell align="center">{row.plan}</TableCell>
                </TableRow>
            );
        });

        return (
            <Table className={classes.table} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Компания</TableCell>

                        {/*отгрузка, наценка */}
                        <TableCell align="center">Отгрузка</TableCell>
                        <TableCell align="center">Наценка %</TableCell>

                        {/*Платеж, Доход */}
                        <TableCell align="center">Платеж</TableCell>
                        <TableCell align="center">Доход</TableCell>

                        {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                        <TableCell align="center">Сумма Заказов</TableCell>
                        <TableCell align="center">Sku</TableCell>
                        <TableCell align="center">Ср. скидка %</TableCell>
                        <TableCell align="center">Кол-во Заказов</TableCell>

                        {/* Недогруз */}
                        <TableCell align="center">Недогруз</TableCell>

                        {/* План */}
                        <TableCell align="center">План %</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {b}
                </TableBody>
                <TableFooter>
                    <TableRow key='total'>
                        <TableCell align="center">Итого</TableCell>

                        {/*отгрузка, наценка */}
                        <TableCell
                            align="center">{tSep((total.shipment_amount - total.refund_amount).toFixed(0))}</TableCell>
                        <TableCell align="center">{total.markup.toFixed(2)}</TableCell>

                        {/*Платеж, Доход */}
                        <TableCell align="center">{tSep(total.payment_amount)}</TableCell>
                        <TableCell align="center">{tSep(total.profit.toFixed(0))}</TableCell>


                        {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                        <TableCell align="center">{tSep(total.orders_amount)}</TableCell>
                        <TableCell align="center">{tSep(total.SKU_amount)}</TableCell>
                        <TableCell align="center">{total.average_discount.toFixed(2)}</TableCell>
                        <TableCell align="center">{tSep(total.orders_total)}</TableCell>


                        {/* Недогруз */}
                        <TableCell align="center">{tSep(total.underloading)}</TableCell>

                        {/* План */}
                        <TableCell align="center">{total.plan.toFixed(2)}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }

};

function tSep(num) {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numParts.join(".");
}

export default withStyles(styles)(SimpleTable);