import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ApiService from '../Components/services/api-service';
import {
    GroupTable,
    SimpleTable,
    TSimpleTable,
    Calender
} from '../Components/tables';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import RefreshIcon from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';
import { green } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import ru from 'moment/locale/ru';

// moment.locale("ru");
// moment.lang('ru');

// import DayjsUtils from '@date-io/dayjs'
// import dayjs from 'dayjs'
// import nl from 'dayjs/locale/nl'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        //padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: '1100px',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    '@media (max-width: 768px)': {
        paper:{
            padding: 0,
            margin: '8 0'
        }
    },
    modalPaper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {

    },
    dialog: {
        width: 400
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    calenderFab: {
        marginLeft: theme.spacing(2),
    },
    fab: {
        position: 'fixed',
        top: theme.spacing(12),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 350,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    }
});

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

class TotalStat extends Component {

    constructor(props){
        super();
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

        if (!window.localStorage.getItem('statStartDate') || !window.localStorage.getItem('statEndDate')) {
            window.localStorage.setItem('statStartDate', firstDay);
            window.localStorage.setItem('statEndDate', date);
        }

        this.state = {
            dataType: 'total',
            start_date: null,
            //start_date: firstDay,
            finish_date: null,
            //finish_date: date,
            loading: false,
            loaded: false,
            emailBlocked: true,
            apiService: new ApiService(),
            data: {},
            columns: [],
            selectedCompany: null,
            selectedCompanyId: null,
            bulk_data: [],
            plan: {},
            bulk_data_selector: 'brand',
            modalOpen: false,
            snackOpen: false,
            modalData: {
                email: '',
                text: ''
            },
            calenderOpen: false,
            events: []
        };
        this.dataReceived = this.dataReceived.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleFinishDateChange = this.handleFinishDateChange.bind(this);
        this.handleCompanySelect = this.handleCompanySelect.bind(this);
        this.backToCompanies = this.backToCompanies.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getFullData = this.getFullData.bind(this);
        this.dataFullReceived = this.dataFullReceived.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onModalChange = this.onModalChange.bind(this);
        this.onModalSend = this.onModalSend.bind(this);
        this.onEmailSent = this.onEmailSent.bind(this);
        this.onEmailError = this.onEmailError.bind(this);
        this.toggleSnack = this.toggleSnack.bind(this);
        this.onChangeSort = this.onChangeSort.bind(this);
        this.closeSnack = this.closeSnack.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.openCalender = this.openCalender.bind(this);
        this.setInitata = this.setInitata.bind(this);
    }

    componentDidMount(){
        //this.refresh();
        this.setInitata();
    }

    dataReceived(data){
        const data_  = [];
        // Object.keys(data.data).forEach((company, index) => {
        //     data_.push({...data.data[company], company: company})
        // });

        this.setState({
            loading: false,
            loaded: true,
            emailBlocked: false,
            //data: data_
            data: data.t_data.data,
            columns: data.t_data.columns
        });
    }

    dataFullReceived(data, events, plan){
        const dd = data[Object.keys(data)[0]];
        this.setState({
            loading: false,
            emailBlocked: false,
            loaded: true,
            bulk_data: dd,
            plan: plan,
            events: events
        });
    }

    handleStartDateChange(date){
        window.localStorage.setItem('statStartDate', date);
        this.setState({
            start_date: date
        });
    }

    handleFinishDateChange(date){
        window.localStorage.setItem('statEndDate', date);
        this.setState({
            finish_date: date
        })
    }

    setInitata(){
        this.setState({
            start_date: window.localStorage.getItem('statStartDate'),
            finish_date: window.localStorage.getItem('statEndDate')
        });
    }

    getFullData(){
        this.refresh(false);
    }

    refresh(simple=true){
        if (window.localStorage.getItem('statStartDate') && window.localStorage.getItem('statEndDate')) {
            const sd = new Date(Date.parse(window.localStorage.getItem('statStartDate')));
            const fd = new Date(Date.parse(window.localStorage.getItem('statEndDate')));

            const sd_month =  parseInt(sd.getMonth()) + 1;
            const fd_month =  parseInt(fd.getMonth()) + 1;

            let data = {
                start_date: `${sd.getFullYear()}-${sd_month}-${sd.getDate()}`,
                finish_date: `${fd.getFullYear()}-${fd_month}-${fd.getDate()}`
            };

            this.setState({
                start_date: window.localStorage.getItem('statStartDate'),
                finish_date: window.localStorage.getItem('statEndDate'),
                loading: true,
                emailBlocked: true,
                loaded: false,
                error: false,
                events: []
            });

            //console.log("simple", simple);

            if (simple !== false) {
                this.state.apiService.getRes(data)
                    .then((res) => {
                        this.dataReceived(res.info)
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({
                            loading: false,
                            emailBlocked: true,
                            loaded: false,
                            data: {},
                            columns: [],
                            error: true
                        });
                    });
            } else {
                data['source_id'] = this.state.selectedCompanyId;
                this.state.apiService.getResFull(data)
                    .then((res) => {
                        this.dataFullReceived(res.info.data, res.info.events, res.info.plan);
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({
                            loading: false,
                            loaded: false,
                            emailBlocked: true,
                            error: true,
                            data: {},
                            columns: [],
                            selectedCompany: null,
                            selectedCompanyId: null,
                            bulk_data: []
                        });
                    });
            }
        }
    }

    handleCompanySelect(event) {
        const c_name = event.target.innerText;
        const c_id = event.target.id;

        this.setState({
            selectedCompany: c_name,
            selectedCompanyId:  c_id,
            dataType: 'perBrand'
        }, this.getFullData);
    }

    backToCompanies(){
        this.setState({
            selectedCompany: null,
            dataType: 'total',
            bulk_data: []
        });
    }

    openModal(){
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    toggleSnack(){
        this.setState({
            snackOpen: !this.state.snackOpen
        });
    }

    closeSnack(){
        this.setState({
            snackOpen: false
        });
    }

    onModalChange(e){
        const modalState = this.state.modalData;
        modalState[e.target.name] = e.target.value;
        this.setState({
            modalState: modalState
        });
    }

    onEmailSent(data){
        this.setState({
            emailBlocked: false,
        });
        console.log(data);
        this.toggleSnack();
    };

    onEmailError(e){
        this.setState({
            emailBlocked: false,
        });
        console.log(e)
    };

    onModalSend(){
        this.setState({
            emailBlocked: true,
        });

        const email = this.state.modalData.email;
        const text = this.state.modalData.text;
        const source_id = this.state.selectedCompanyId;

        if(!validateEmail(email)){
            return false;
        }

        if (this.state.loaded === false) {
            return false;
        }


        let info = false;
        if (this.state.dataType === 'total'){
            if (this.state.data !== {}){
                info = true;
            }
        } else {
            if (this.state.bulk_data !== {}){
                info = true;
            }
        }

        if (info === false) {
            return false;
        }

        this.openModal();


        const sd = new Date(this.state.start_date);
        const fd = new Date(this.state.finish_date);

        const sd_month =  parseInt(sd.getMonth()) + 1;
        const fd_month =  parseInt(fd.getMonth()) + 1;


        const data = {
            start_date: `${sd.getFullYear()}-${sd_month}-${sd.getDate()}`,
            finish_date: `${fd.getFullYear()}-${fd_month}-${fd.getDate()}`,
            data_type: this.state.dataType,
            email: email,
            text: text,
            selector: this.state.bulk_data_selector,
            source_id: (source_id) ? source_id : 0
        };

        console.log("data", data);

        if (true) {
            this.state.apiService.sendEmail(data)
                .then((res) => {
                    this.onEmailSent(res)
                })
                .catch((e) => {
                    this.onEmailError(e);
                });
        }

    }

    onChangeSort(by){
        this.setState({
            bulk_data_selector: by
        });
    }

    openCalender(){
        this.setState({
            calenderOpen: true
        }, () => {console.log("calenderOpen", this.state.calenderOpen)});
    }

    closeCalender(){
        this.setState({
            calenderOpen: false
        }, () => {console.log("calenderOpen", this.state.calenderOpen)});
    }

    render () {
        if (!this.props.isAuthenticated) {
            return <Redirect to='/login'/>;
        }

        const { classes } = this.props;

        //this.props.setTitle("Общая Статистика");


        let info = null;
        if (this.state.loading === true) {
            info = <CircularProgress />;
        } else {
            if (this.state.loaded === false) {
                this.props.setTitle("Общая Статистика");
                if (this.state.error) {
                    info = 'Неизвестная ошибка, попробуйте еще раз...'
                } else {
                    info = 'Укажите диапазон и нажмите "Обновить"'
                }
            } else {
                if (this.state.dataType === 'total') {
                    this.props.setTitle("Общая Статистика");
                    //info = <SimpleTable data={this.state.data} handleSelect={this.handleCompanySelect}/>
                    info = <TSimpleTable data={this.state.data} columns={this.state.columns} handleSelect={this.handleCompanySelect}/>
                } else {
                    this.props.setTitle(`Статистика для ${this.state.selectedCompany}`);
                    info = <GroupTable plan={this.state.plan} data={this.state.bulk_data} selector={this.state.bulk_data_selector} onSelectorChange={this.onChangeSort} />
                }
            }

        }

        const calender = (this.state.calenderOpen) ? <Calender data={this.state.events} open={this.state.calenderOpen} handleClose={this.closeCalender}/> : '';

        const disablePeackers = (this.state.dataType !== 'total') ? true : false;

        const modalStyle = getModalStyle();

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    {/*<MuiPickersUtilsProvider utils={DayjsUtils} locale='nl'>*/}
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <KeyboardDatePicker
                                    style={{marginTop: 0}}
                                    locale={'ru'}
                                    disabled={disablePeackers}
                                    disableFuture={true}
                                    margin="normal"
                                    id="start_date"
                                    name="start_date"
                                    label="Дата от"
                                    format="yyyy-MM-dd"
                                    value={this.state.start_date}
                                    onChange={this.handleStartDateChange}
                                    cancelLabel="Отмена"
                                    okLabel="OK"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <KeyboardDatePicker
                                    style={{marginTop: 0}}
                                    locale={'ru'}
                                    disabled={disablePeackers}
                                    disableFuture={true}
                                    margin="normal"
                                    id="finish_date"
                                    name="finish_date"
                                    label="Дата до"
                                    format="yyyy-MM-dd"
                                    value={this.state.finish_date}
                                    onChange={this.handleFinishDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Box mb={1}>
                    </Box>
                    {(this.state.dataType !== 'total') &&
                        <React.Fragment>
                            <Fab
                                size="small"
                                onClick={this.backToCompanies}
                                disabled={(this.state.loading) ? true : false}
                                variant="extended">
                                <NavigationIcon className={classes.extendedIcon} />
                                Назад
                            </Fab>
                            <Fab
                                size="small"
                                className={classes.calenderFab}
                                onClick={this.openCalender}
                                disabled={(this.state.loading) ? true : false}
                                variant="extended">
                                <EventNoteIcon className={classes.extendedIcon} />
                                Поставщик
                            </Fab>
                        </React.Fragment>
                    }

                    {(this.state.dataType === 'total') &&
                        <Fab
                            size="small"
                            onClick={this.refresh}
                            disabled={(this.state.loading) ? true : false}
                            variant="extended">
                            <RefreshIcon className={classes.extendedIcon} />
                            Обновить
                        </Fab>
                    }

                    <Box mb={2}>
                    </Box>

                    {info}

                </Paper>

                <Dialog
                    open={this.state.modalOpen} onClose={this.openModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Отправить Email</DialogTitle>
                    <DialogContent className={classes.dialog}>
                        <TextField
                            required
                            label="Email address"
                            name="email"
                            type="email"
                            id="outlined-margin-dense"
                            //defaultValue="Email address"
                            //className={classes.textField}
                            //helperText="Some important text"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={this.onModalChange}
                            value={this.state.modalData.email}
                        />
                        <Box mb={3}>
                        </Box>
                        <TextField
                            required
                            id="text"
                            name="text"
                            label="Text"
                            fullWidth
                            autoComplete="text"
                            multiline
                            rows="4"
                            variant="outlined"
                            value={this.state.modalData.text}
                            onChange={this.onModalChange}
                            //sonChange={this.onChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.openModal} color="primary">
                            Отмена
                        </Button>
                        <Button onClick={this.onModalSend} color="primary">
                            Отправить
                        </Button>
                    </DialogActions>
                </Dialog>

                {false &&
                    <Fab
                        aria-label={'Expand'}
                        className={clsx(classes.fab, classes.fabGreen)}
                        disabled={(this.state.emailBlocked) ? true : false}
                        color={'inherit'}
                        onClick={this.openModal}
                    >
                        <MailIcon/>
                    </Fab>
                }

                <Snackbar open={this.state.snackOpen} onClose={this.closeSnack} autoHideDuration={3000}>
                    <Alert onClose={this.closeSnack} severity="success">
                        Email was sent!
                    </Alert>
                </Snackbar>

                {calender}

            </div>
        );
    }
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default withStyles(styles)(TotalStat);
