import React, {Component} from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import TableContainer from '@material-ui/core/TableContainer';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortLabel from './sort-label';


function pxToRem(value) {
    return `${value / 16}rem`;
}

const t_groups = ['shipment', 'payment', 'orders', 'plan', 'planMonthly'];

const math_fields = [
    'payment_amount',
    'shipment_amount',
    'refund_amount',
    'profit',
    'markup',
    'plan',
    'SKU_amount',
    'average_discount',
    'orders_amount',
    'underloading',
    'orders_total',
    'prod_cost',
    'amount_without_discount'
];

//const ave_fields = ['markup', 'average_discount'];
const ave_fields = [];
const total_ave_fields = ['markup', 'average_discount'];

const fields_map = {
    company: "Компания",
    brand: "Бренд",
    manager: "Менеджер",
    unit: "Подразделение"
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    gridButton: {
       margin: 'auto'
    },
    table: {
        minHeight: 600
    },
    buttonsWrapper: {
        '& > *': {
            margin: theme.spacing(1),
        },
        textAlign: 'center'
    },
    addTd: {
        padding: '6px',
        fontSize: '0.8rem',
        color: '#696969'
    },
    '@media (max-width: 960px)': {
        cell:{
            padding: '6px'
        },
        addTd: {
            padding: '3px'
        }
    },
    '@media (max-width: 700px)': {
        cell:{
            padding: '3px',
            fontSize: '11px',
            verticalAlign: 'baseline',
            minWidth: '60px'

        },
        addTd:{
            padding: '3px',
            fontSize: '11px',
            verticalAlign: 'baseline',
            minWidth: '60px'
        },
        sortLabel: {
            display: 'block',
            margin: 0,
            padding: 0
        },
        arrow: {
            display: 'block !important',
            margin: 0,
            padding: 0
        }
    }

});

const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
    breakpoints,
    overrides: {
        MuiTypography: {
            th: {
                fontSize: pxToRem(8),
                [breakpoints.up("md")]: {
                    fontSize: pxToRem(8)
                }
            }
        }
    }
});

class GroupTable extends Component {
    constructor(props) {
        super();
        this.state = {
            data: props.data,
            plan: props.plan,
            planMonthly: props.planMonthly,
            selector: props.selector,
            folded: {
                shipment: true,
                payment: true,
                orders: true,
                plan: true,
                planMonthly: true
            },
            width: 0,
            height: 0,
            open: [],
            sortBy: null,
            sortOrder: 'asc'
        };
        this.handleGroup = this.handleGroup.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    handleGroup(selector){
        //console.log(selector);
        this.setState({
            selector: selector
        });

        this.props.onSelectorChange(selector);
    }

    toggleGroup(g_name){
        if (t_groups.includes(g_name)) {
            const folded = this.state.folded;
            folded[g_name] = !folded[g_name];
            this.setState({folded: folded});
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleOpen(e){
        if (this.state.open.includes(e.target.id)){
            const open = this.state.open.filter((i, k) => {return i !== e.target.id});
            this.setState({
                open: open
            });
        } else {
            const open = this.state.open;
            open.push(e.target.id);
            this.setState({
                open: open
            });
        }
    }

    handleSort(by){
        let {sortBy, sortOrder} = this.state;
        if (by == sortBy) {
            sortOrder = (sortOrder === 'asc') ? 'desc' : 'asc';
        } else {
            sortBy = by;
            sortOrder = 'desc'
        }

        this.setState({
            sortBy,
            sortOrder
        }, () => {console.log(this.state.sortBy, this.state.sortOrder)});
    }

    render(){
        const { classes } = this.props;
        const {selector, data, height, sortBy, sortOrder, plan} = this.state;


        const plan_shipment_data = plan[`shipment_plan_by_${selector}`];
        const plan_payment_data = plan[`payment_plan_by_${selector}`];

        let plan_shipment_data_total = 0.0;
        let plan_payment_data_total = 0.0;
        if (plan_shipment_data) {
            Object.keys(plan_shipment_data).map((e, i) => {
                plan_shipment_data_total = plan_shipment_data_total + plan_shipment_data[e];
                plan_payment_data_total = plan_payment_data_total + plan_payment_data[e];
            });
        }

        const plan_shipment_data_monthly = plan[`shipment_plan_by_${selector}_monthly`];
        const plan_payment_data_monthly = plan[`payment_plan_by_${selector}_monthly`];

        let plan_shipment_data_monthly_total = 0.0;
        let plan_payment_data_monthly_total = 0.0;
        if (plan_shipment_data_monthly) {
            Object.keys(plan_shipment_data_monthly).map((e, i) => {
                plan_shipment_data_monthly_total = plan_shipment_data_monthly_total + plan_shipment_data_monthly[e];
                plan_payment_data_monthly_total = plan_payment_data_monthly_total + plan_payment_data_monthly[e];
            });
        }

        //console.log(plan_shipment_data_total, plan_payment_data_total);

        const align = 'right';

        const {new_data, all_data} = groupData(data, selector);

        const unit_manager = {};
        if (selector === 'unit') {
            const {new_data: new_data_dop, all_data: all_data_dop} = groupData(data, 'manager', true);

            Object.keys(new_data).forEach((key, index) => {
                unit_manager[key] = Object.values(new_data_dop).filter((el, ind) => {return el['unit'] === key});
            });
        }

        const all_data_markup = [];
        const all_data_discount = [];

        const all_data_shipment_amount = []
        const all_data_prod_cost = []


        // const another_data = [];
        // Object.keys(new_data).forEach((row_name, index) => {
        //     another_data.push(new_data[row_name]);
        // });

        let another_data_sorted = Object.values(new_data);
        if (sortBy !== null) {
            //console.log('sorting...');
            another_data_sorted = Object.values(new_data).sort((a, b) => {
                if (sortOrder === 'asc') {
                    return (a[sortBy] > b[sortBy]) ? 1 : -1;
                } else {
                    return (a[sortBy] < b[sortBy]) ? 1 : -1;
                }
            });
        }

        // console.log("sortBy", sortBy);
        // console.log("sortOrder", sortOrder);
        // console.log("another_data_sorted", another_data_sorted);

        const b = [];
        another_data_sorted.forEach((row, index) => {
        //Object.keys(new_data).forEach((row_name, index) => {
        //    const row = new_data[row_name];

            let addRows = null;
            if (selector === 'unit') {
                addRows = unit_manager[row['unit']];
            }

            const {shipment_amount, prod_cost, orders_amount, amount_without_discount} = row;

            all_data_shipment_amount.push(shipment_amount)
            all_data_prod_cost.push(prod_cost)


            let avg = 0;
            if (prod_cost !== 0){
                avg = ( (shipment_amount / prod_cost) - 1) * 100;
            }
            all_data_markup.push(avg);

            let avg_discount = 0;
            if (amount_without_discount !== 0){
                avg_discount = 100 - ((orders_amount / amount_without_discount)  * 100);
            }
            all_data_discount.push(avg_discount);

            let icon = '';
            if (selector === 'unit') {
                icon = (!this.state.open.includes(row['unit'])) ? <ArrowRightIcon/> :
                    <ArrowDropDownIcon/>;
            }

            //console.log("row[selector]", row[selector]);

            const shipment_plan_single = (plan_shipment_data[row[selector]] != undefined) ? tSep(plan_shipment_data[row[selector]].toFixed(0)) : 0.0;
            const payment_plan_single = (plan_payment_data[row[selector]] != undefined) ? tSep(plan_payment_data[row[selector]].toFixed(0)) : 0.0;

            const shipment_plan_single_monthly = (plan_shipment_data_monthly[row[selector]] != undefined) ? tSep(plan_shipment_data_monthly[row[selector]].toFixed(0)) : 0.0;
            const payment_plan_single_monthly = (plan_payment_data_monthly[row[selector]] != undefined) ? tSep(plan_payment_data_monthly[row[selector]].toFixed(0)) : 0.0;            

            //console.log("payment_plan_single_monthly", payment_plan_single_monthly)

            //return (
            b.push(
                <TableRow key={index}>
                    <TableCell
                        className={classes.cell}
                        align={align}
                        style={{'cursor': 'pointer'}}
                        id={row[selector]}
                        onClick={this.handleOpen}>
                        {row[selector]} {icon}
                    </TableCell>

                    {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                    <TableCell
                        className={classes.cell}
                        style={{backgroundColor: "#3f51b514"}}
                        align={align}>{tSep(row.orders_amount.toFixed(0))}</TableCell>
                    {(this.state.folded['orders'] !== true) &&
                    <React.Fragment>
                        {/*<TableCell*/}
                        {/*style={{backgroundColor: "#3f51b514"}}*/}
                        {/*align="center">{tSep(row.SKU_amount)}</TableCell>*/}
                        {/*<TableCell*/}
                        {/*style={{backgroundColor: "#3f51b514"}}*/}
                        {/*align="center">{avg_discount.toFixed(2)}</TableCell>*/}
                        <TableCell
                            className={classes.cell}
                            style={{backgroundColor: "#3f51b514"}}
                            align={align}>{tSep(row.orders_total)}</TableCell>
                    </React.Fragment>
                    }


                    {/*отгрузка, наценка */}
                    <TableCell
                        className={classes.cell}
                        //style={{backgroundColor: "#3f51b514"}}
                        align={align}>{tSep((row.shipment_amount - row.refund_amount).toFixed(0))}</TableCell>
                        {/*align="center">{tSep((row.shipment_amount).toFixed(0))}</TableCell>*/}
                    { (this.state.folded['shipment'] !== true) &&
                        <React.Fragment>
                            <TableCell
                                className={classes.addTd}
                                //style={{backgroundColor: "#3f51b514"}}
                                align={align}>{tSep(row.shipment_amount.toFixed(0))}</TableCell>
                            <TableCell
                                className={classes.addTd}
                                //style={{backgroundColor: "#3f51b514"}}
                                align={align}>{tSep(row.refund_amount.toFixed(0))}</TableCell>
                            <TableCell
                                className={classes.addTd}
                                //style={{backgroundColor: "#3f51b514"}}
                                align={align}>{avg.toFixed(2)}</TableCell>
                        </React.Fragment>
                    }

                    {/*Платеж, Доход */}
                    <TableCell
                        className={classes.cell}
                        style={{backgroundColor: "#3f51b514"}}
                        align={align}>{tSep(row.payment_amount.toFixed(0))}</TableCell>

                    {(this.state.folded['payment'] !== true) &&
                        <TableCell
                            className={classes.cell}
                            style={{backgroundColor: "#3f51b514"}}
                            align={align}>{tSep(row.profit.toFixed(0))}</TableCell>
                    }


                    {/* Недогруз */}
                    {false &&
                        <TableCell className={classes.cell} align={align}>{tSep(row.underloading.toFixed(0))}</TableCell>
                    }

                    {/* План */}
                    {/*<TableCell className={classes.cell} align={align}>{row.plan.toFixed(2)}</TableCell>*/}
                    <TableCell
                        className={classes.cell}
                        style={{backgroundColor: "#3f51b514"}}
                        align={align}>{shipment_plan_single}</TableCell>

                    {(this.state.folded['plan'] !== true) &&
                        <TableCell
                            className={classes.cell}
                            style={{backgroundColor: "#3f51b514"}}
                            align={align}>{payment_plan_single}</TableCell>
                    }

                    {/* План Мес*/}
                    {/*<TableCell className={classes.cell} align={align}>{row.plan.toFixed(2)}</TableCell>*/}
                    <TableCell
                        className={classes.cell}
                        style={{backgroundColor: "#3f51b514"}}
                        align={align}>{shipment_plan_single_monthly}</TableCell>

                    {(this.state.folded['planMonthly'] !== true) &&
                        <TableCell
                            className={classes.cell}
                            style={{backgroundColor: "#3f51b514"}}
                            align={align}>{payment_plan_single_monthly}</TableCell>
                    }                    
                </TableRow>
            );

            if (selector === 'unit' && this.state.open.includes(row[selector])) {
                addRows.forEach((row, ind) => {
                    b.push(
                        <TableRow key={`add_${index}_${ind}`}>
                            <TableCell
                                className={classes.addTd}
                                align={align}
                                //id={row['manager']}
                                >
                                {row['manager']}
                            </TableCell>

                            {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                            <TableCell
                                className={classes.addTd}
                                style={{backgroundColor: "#3f51b514"}}
                                align={align}>{tSep(row.orders_amount.toFixed(0))}</TableCell>
                            {(this.state.folded['orders'] !== true) &&
                            <React.Fragment>
                                {/*<TableCell*/}
                                {/*className={classes.addTd}*/}
                                {/*style={{backgroundColor: "#3f51b514"}}*/}
                                {/*align="center">{tSep(row.SKU_amount)}</TableCell>*/}
                                {/*<TableCell*/}
                                {/*className={classes.addTd}*/}
                                {/*style={{backgroundColor: "#3f51b514"}}*/}
                                {/*align="center">{avg_discount.toFixed(2)}</TableCell>*/}
                                <TableCell
                                    className={classes.addTd}
                                    style={{backgroundColor: "#3f51b514"}}
                                    align={align}>{tSep(row.orders_total)}</TableCell>
                            </React.Fragment>
                            }

                            {/*отгрузка, наценка */}
                            {/*отгрузка, факт. отгр, возврат, наценка */}
                            <TableCell
                                className={classes.addTd}
                                //style={{backgroundColor: "#3f51b514"}}
                                align={align}>{tSep((row.shipment_amount - row.refund_amount).toFixed(0))}</TableCell>
                            { (this.state.folded['shipment'] !== true) &&
                                <React.Fragment>
                                    <TableCell
                                        className={classes.addTd}
                                        //style={{backgroundColor: "#3f51b514"}}
                                        align={align}>{tSep(row.shipment_amount.toFixed(0))}</TableCell>
                                    <TableCell
                                        className={classes.addTd}
                                        //style={{backgroundColor: "#3f51b514"}}
                                        align={align}>{tSep(row.refund_amount.toFixed(0))}</TableCell>
                                    <TableCell
                                        className={classes.addTd}
                                        //style={{backgroundColor: "#3f51b514"}}
                                        align={align}>{avg.toFixed(2)}</TableCell>
                                </React.Fragment>
                            }


                            {/*Платеж, Доход */}
                            <TableCell
                                style={{backgroundColor: "#3f51b514"}}
                                className={classes.addTd} align={align}>{tSep(row.payment_amount.toFixed(0))}</TableCell>

                            {(this.state.folded['payment'] !== true) &&
                            <TableCell
                                style={{backgroundColor: "#3f51b514"}}
                                className={classes.addTd} align={align}>{tSep(row.profit.toFixed(0))}</TableCell>
                            }


                            {/* Недогруз */}
                            {false &&
                            <TableCell className={classes.addTd}
                                       align={align}>{tSep(row.underloading.toFixed(0))}</TableCell>
                            }

                            {/* План */}
                            <TableCell className={classes.addTd} align={align}>{row.plan.toFixed(2)}</TableCell>

                            {/* План Мес*/}
                            <TableCell className={classes.addTd} align={align}></TableCell>
                        </TableRow>
                    );
                });
            }
        });

        // markup
        let all_data_avg = 0;
        if (all_data_shipment_amount.length !== 0){
            if (all_data_shipment_amount.length === 1){
                if (all_data_prod_cost[0] !== 0){
                    all_data_avg = ( (all_data_shipment_amount[0] / all_data_prod_cost[0]) - 1) * 100;
                }
            } else {
                const av_prod_cost = average(all_data_prod_cost)
                if (av_prod_cost !== 0) {
                    all_data_avg = ( (average(all_data_shipment_amount) / av_prod_cost) - 1) * 100;
                }
            }
        }

        // if (all_data_markup.length !== 0){
        //     if (all_data_markup.length === 1){
        //         all_data_avg = all_data_markup[0];
        //     }else {
        //         const all_data_sum = all_data_markup.reduce((a, b) => a + b, 0);
        //         all_data_avg = (all_data_sum / all_data_markup.length) || 0;
        //     }
        // }

        //average_discount
        let all_data_avg_discount = 0;
        if (all_data_discount.length !== 0) {
            if (all_data_discount.length === 1) {
                all_data_avg_discount = all_data_discount[0];
            } else {
                const all_data_d_sum = all_data_discount.reduce((a, b) => a + b, 0);
                all_data_avg_discount = (all_data_d_sum / all_data_discount.length) || 0;
            }
        }

        const shipmentIcon = (this.state.folded['shipment'] !== true) ? <ArrowRightIcon className={classes.arrow}/> : <ArrowDropDownIcon className={classes.arrow} />;
        const paymentIcon = (this.state.folded['payment'] !== true) ? <ArrowRightIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />;
        const planIcon = (this.state.folded['plan'] !== true) ? <ArrowRightIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />;
        const planMonthlyIcon = (this.state.folded['planMonthly'] !== true) ? <ArrowRightIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />;
        const ordersIcon = (this.state.folded['orders'] !== true) ? <ArrowRightIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />;
        const activeSort = this.state.sortBy;

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid style={{textAlign: 'center'}} item xs={6} sm={6}>
                        <Button
                            onClick={() => this.handleGroup('brand') }
                            disabled={(this.state.selector === 'brand') ? true : false}
                            className={classes.gridButton}
                            color={"primary"}
                            //variant="outlined"
                            variant="contained"
                        >
                            Бренд
                        </Button>
                    </Grid>
                    <Grid style={{textAlign: 'center'}} item xs={6} sm={6}>
                        <Button
                            onClick={() => this.handleGroup('unit') }
                            disabled={(this.state.selector === 'unit') ? true : false}
                            className={classes.gridButton}
                            color={"primary"}
                            //variant="outlined"
                            variant="contained"
                        >
                            Подразделение
                        </Button>
                    </Grid>
                    <Grid style={{textAlign: 'center'}} item xs={12} sm={4}>
                        {/*<Button*/}
                        {/*onClick={() => this.handleGroup('manager')}*/}
                        {/*disabled={(this.state.selector === 'manager') ? true : false}*/}
                        {/*className={classes.gridButton}*/}
                        {/*color={"primary"}*/}
                        {/*//variant="outlined"*/}
                        {/*variant="contained"*/}
                        {/*>*/}
                        {/*Менеджер*/}
                        {/*</Button>*/}
                    </Grid>
                </Grid>
                <Box mb={3}>
                </Box>
                <TableContainer
                    //height="100%"
                    //style={{maxHeight: height - 320}}
                    style={{maxHeight: ((height > 800) ? height - 320 : height)}}
                >
                    <Table stickyHeader className={classes.table} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.cell}
                                    style={{maxWidth: '200px', position: 'sticky', top: 0}} align="center">
                                    <SortLabel
                                        className={classes.sortLabel}
                                        onClick={() => this.handleSort(selector)}
                                        active={(activeSort === selector)}
                                        style={{marginRight: '10px'}}/> {fields_map[selector]}
                                </TableCell>

                                {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                                <TableCell
                                    className={classes.cell}
                                    // style={{position: 'sticky', top: 0}}
                                    //onClick={() => {this.toggleGroup('orders')}}
                                    align="center">
                                    <SortLabel
                                        className={classes.sortLabel}
                                        onClick={() => this.handleSort('orders_amount')}
                                        active={(activeSort === 'orders_amount')}
                                        style={{marginRight: '10px'}}/>

                                    <span  onClick={() => {this.toggleGroup('orders')}}>
                                        Сумма Заказов {ordersIcon}
                                    </span>
                                </TableCell>
                                {(this.state.folded['orders'] !== true) &&
                                <React.Fragment>
                                    {/*<TableCell*/}
                                    {/*// style={{position: 'sticky', top: 0}}*/}
                                    {/*onClick={() => {this.toggleGroup('orders')}}*/}
                                    {/*align="center">Sku</TableCell>*/}
                                    {/*<TableCell*/}
                                    {/*// style={{position: 'sticky', top: 0}}*/}
                                    {/*onClick={() => {this.toggleGroup('orders')}}*/}
                                    {/*align="center">Ср. скидка %</TableCell>*/}
                                    <TableCell
                                        className={classes.cell}
                                        // style={{position: 'sticky', top: 0}}
                                        onClick={() => {this.toggleGroup('orders')}}
                                        align="center">{<ArrowLeftIcon color={'disabled'}/>} Кол-во Заказов</TableCell>
                                </React.Fragment>
                                }


                                {/*отгрузка, наценка */}
                                {/*отгрузка, факт. отгр, возврат, наценка */}
                                <TableCell
                                    className={classes.cell}
                                    // style={{position: 'sticky', top: 0}}

                                    align="center">
                                    <SortLabel
                                        className={classes.sortLabel}
                                        onClick={() => this.handleSort('shipment_amount')}
                                        active={(activeSort === 'shipment_amount')}
                                        style={{marginRight: '10px'}}/>
                                    <span onClick={() => {this.toggleGroup('shipment')}}>
                                        Отгрузка {shipmentIcon}
                                    </span>
                                </TableCell>
                                {(this.state.folded['shipment'] !== true) &&
                                    <React.Fragment>
                                        <TableCell
                                            className={classes.cell}
                                            style={{position: 'sticky', top: 0}}
                                            onClick={() => {this.toggleGroup('shipment')}}
                                            align="center">
                                            Факт. Отгр.
                                        </TableCell>
                                        <TableCell
                                            className={classes.cell}
                                            style={{position: 'sticky', top: 0}}
                                            onClick={() => {this.toggleGroup('shipment')}}
                                            align="center">
                                            Возврат
                                        </TableCell>
                                        <TableCell
                                            className={classes.cell}
                                            style={{position: 'sticky', top: 0}}
                                            onClick={() => {this.toggleGroup('shipment')}}
                                            align="center">
                                            {<ArrowLeftIcon color={'disabled'}/>} Наценка %
                                        </TableCell>
                                    </React.Fragment>
                                }

                                {/*Платеж, Доход */}
                                <TableCell
                                    className={classes.cell}
                                    // style={{position: 'sticky', top: 0}}
                                    //onClick={() => {this.toggleGroup('payment')}}
                                    align="center">
                                    <SortLabel
                                        className={classes.sortLabel}
                                        onClick={() => this.handleSort('payment_amount')}
                                        active={(activeSort === 'payment_amount')}
                                        style={{marginRight: '10px'}}/>

                                    <span  onClick={() => {this.toggleGroup('payment')}}>
                                        Платеж {paymentIcon}
                                    </span>
                                </TableCell>
                                {(this.state.folded['payment'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{position: 'sticky', top: 0}}
                                        onClick={() => {this.toggleGroup('payment')}}
                                        align="center">{<ArrowLeftIcon color={'disabled'}/>} Доход</TableCell>
                                }



                                {/* Недогруз */}
                                {false &&
                                <TableCell
                                    className={classes.cell}
                                    // style={{position: 'sticky', top: 0}}
                                    align="center">
                                    <SortLabel
                                        className={classes.sortLabel}
                                        onClick={() => this.handleSort('underloading')}
                                        active={(activeSort === 'underloading')}
                                        style={{marginRight: '10px'}}/>

                                    <span>
                                        Недогруз
                                    </span>
                                </TableCell>
                                }
                                {/* План */}
                                <TableCell
                                    className={classes.cell}
                                    align="center">
                                    <span  onClick={() => {this.toggleGroup('plan')}}>
                                        ПланДниОтгр {planIcon}
                                    </span>
                                </TableCell>

                                {(this.state.folded['plan'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{position: 'sticky', top: 0}}
                                        onClick={() => {this.toggleGroup('plan')}}
                                        align="center">{<ArrowLeftIcon color={'disabled'}/>} ПланДниПл</TableCell>
                                }

                                {/* План Мес*/}
                                <TableCell
                                    className={classes.cell}
                                    align="center">
                                    <span  onClick={() => {this.toggleGroup('planMonthly')}}>
                                        ПланМесОтгр {planMonthlyIcon}
                                    </span>
                                </TableCell>

                                {(this.state.folded['planMonthly'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{position: 'sticky', top: 0}}
                                        onClick={() => {this.toggleGroup('planMonthly')}}
                                        align="center">{<ArrowLeftIcon color={'disabled'}/>} ПланМесПл</TableCell>
                                }                                

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {b}
                        </TableBody>
                        <TableFooter>
                            <TableRow key='total'>
                                <TableCell className={classes.cell} align={align}>Итого</TableCell>

                                {/* Заказ (сумма заказов), sku, ср. скидка, Кол-во Заказов */}
                                <TableCell
                                    className={classes.cell}
                                    style={{backgroundColor: "#3f51b514"}}
                                    align={align}>{tSep(all_data.orders_amount.toFixed(0))}</TableCell>
                                {(this.state.folded['orders'] !== true) &&
                                <React.Fragment>
                                    {/*<TableCell*/}
                                    {/*style={{backgroundColor: "#3f51b514"}}*/}
                                    {/*align="center">{tSep(all_data.SKU_amount)}</TableCell>*/}
                                    {/*<TableCell*/}
                                    {/*style={{backgroundColor: "#3f51b514"}}*/}
                                    {/*align="center">{all_data_avg_discount.toFixed(2)}</TableCell>*/}
                                    <TableCell
                                        className={classes.cell}
                                        style={{backgroundColor: "#3f51b514"}}
                                        align={align}>{tSep(all_data.orders_total)}</TableCell>
                                </React.Fragment>
                                }

                                {/*отгрузка, наценка */}
                                {/*отгрузка, факт. отгр, возврат, наценка */}
                                <TableCell
                                    className={classes.cell}
                                    align={align}>{tSep((all_data.shipment_amount - all_data.refund_amount).toFixed(0))}</TableCell>
                                {(this.state.folded['shipment'] !== true) &&
                                    <React.Fragment>
                                        <TableCell
                                            className={classes.cell}
                                            align={align}>{tSep(all_data.shipment_amount.toFixed(0))}</TableCell>

                                        <TableCell
                                            className={classes.cell}
                                            //style={{backgroundColor: "#3f51b514"}}
                                            align={align}>{tSep(all_data.refund_amount.toFixed(0))}</TableCell>

                                        <TableCell
                                            className={classes.cell}
                                            //style={{backgroundColor: "#3f51b514"}}
                                            align={align}>{all_data_avg.toFixed(2)}</TableCell>
                                    </React.Fragment>
                                }

                                {/*Платеж, Доход */}
                                <TableCell
                                    className={classes.cell}
                                    style={{backgroundColor: "#3f51b514"}}
                                    align={align}>{tSep(all_data.payment_amount.toFixed(0))}</TableCell>
                                {(this.state.folded['payment'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{backgroundColor: "#3f51b514"}}
                                        align={align}>{tSep(all_data.profit.toFixed(0))}</TableCell>
                                }



                                {/* Недогруз */}
                                {false &&
                                <TableCell className={classes.cell}
                                           align={align}>{tSep(all_data.underloading.toFixed(0))}</TableCell>
                                }

                                {/* План */}
                                <TableCell
                                    className={classes.cell}
                                    style={{backgroundColor: "#3f51b514"}}
                                    align={align}>{tSep(plan_shipment_data_total.toFixed(0))}</TableCell>
                                {(this.state.folded['plan'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{backgroundColor: "#3f51b514"}}
                                       align={align}>{tSep(plan_payment_data_total.toFixed(0))}</TableCell>
                                }

                                {/* План Мес*/}
                                <TableCell
                                    className={classes.cell}
                                    style={{backgroundColor: "#3f51b514"}}
                                    align={align}>{tSep(plan_shipment_data_monthly_total.toFixed(0))}</TableCell>
                                {(this.state.folded['planMonthly'] !== true) &&
                                    <TableCell
                                        className={classes.cell}
                                        style={{backgroundColor: "#3f51b514"}}
                                       align={align}>{tSep(plan_payment_data_monthly_total.toFixed(0))}</TableCell>
                                }
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </MuiThemeProvider>
        );
    }
}

function groupData(data, selector, add=false) {
    const new_data = {};
    const all_data = {};

    math_fields.forEach((field) => {
        if (total_ave_fields.includes(field)){
            all_data[field] = [];
        } else {
            all_data[field] = 0;
        }
    });

    // проход по списку данных
    data.forEach((el, index) => {
        const selector_key = el[selector];

        // создаем дефолтные поля
        if (new_data[selector_key] === undefined) {
            new_data[selector_key] = {[selector]: selector_key};
            //console.log('new_data', new_data);
            math_fields.forEach((field) => {
                if (ave_fields.includes(field)) {
                    new_data[selector_key][field] = [];
                } else {
                    new_data[selector_key][field] = 0;
                }
            });
        }

        new_data[selector_key]['unit'] = el['unit'];
        if (add) {
            new_data[selector_key]['add'] = true;
        }

        const update = {};
        math_fields.forEach((field) => {
            if (field === 'plan') {

            }  else if (ave_fields.includes(field)) {
                new_data[selector_key][field].push(parseFloat(el[field]));
            } else {
                update[field] = new_data[selector_key][field] + parseFloat(el[field]);
            }
        });

        new_data[selector_key] = {...new_data[selector_key], ...update};

        math_fields.forEach((field) => {
            if (field === 'plan') {
            }  else if (total_ave_fields.includes(field)) {
                all_data[field].push(parseFloat(el[field]));
            } else {
                all_data[field] = all_data[field] + parseFloat(el[field]);
            }
        });
    });

    return {
        new_data: new_data,
        all_data: all_data
    }
}

function tSep(num) {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numParts.join(".");
}

const average = (array) => array.reduce((a, b) => a + b) / array.length;

export default withStyles(styles)(GroupTable);
