import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        //padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: '1200px',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    margin: {
        margin: `${theme.spacing(1)}px auto`,
    },
    textField: {
        //width: 200,
    },
    button: {
        margin: `${theme.spacing(1)}px 0`,
    },
});

const Help = ({classes, setTitle}) => {
    setTitle('Help');
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h6">
                  Общая информация:
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Для отображения корректной информации и во избежание открытия кэшированных страниц, рекомендуется использование кнопки RELOAD в правом верхнем углу сайта:
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/1.png" alt="" style={{width: '100%'}}/>
                </div>
                <Typography variant="subtitle1" gutterBottom>
                    Для отображения информации по интересующему периоду, укажите диапазон дат в календарях и нажмите кнопку ОБНОВИТЬ.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Сервис отображает актуальную информацию за последний 40 дней (рекомендуемый период для запроса).
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Для отображения информации по отдельной компании необходимо кликнуть по названию компании:
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/2.png" alt="" style={{width: '100%'}}/>
                </div>
                <Typography variant="h6">
                  Статистика по отдельной компании имеет вид:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/3.png" alt="" style={{width: '100%'}}/>
                </div>
                <Typography variant="subtitle1" gutterBottom>
                    При этом информация доступна в разрезе Брендов (клик по кнопке Бренд) или в разрезе отделов (клик по кнопке Подразделение).
                </Typography>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                  Статистика в разрезе Брендов имеет вид:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/4.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                  Данные по каждому столбцу сортируются по значкам:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/5.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                  Статистика в разрезе Отделов имеет вид:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/6.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                Каждый отдел разворачивается в разрезе сотрудников, для этого нужно кликнуть по названию Отдела:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/7.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                План Поставок по Брендам открывается по кнопке Поставщик:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/8.png" alt="" style={{width: '100%'}}/>
                </div>
                <Typography variant="subtitle1" gutterBottom>
                И имеет вид:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/9.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                Пролистать план помесячно или посмотреть на сегодняшний день:
                </Typography>
                <br/>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/10.png" alt="" style={{width: '100%'}}/>
                </div>
                <br/>
                <br/>

                <Typography variant="h6">
                  Пояснения к полям:
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                Сумма всех созданных (проведенных) заказов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/11.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Количество созданных (проведенных) заказов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/12.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Сумма фактически отгруженных заказов минус сумма возвратов, за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/13.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Сумма всех отгруженных заказов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/14.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Сумма всех возвратов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/15.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Наценка = (Учет – Себестоимость)/ Себестоимость, за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/16.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                Платеж – сумма всех поступивших платежей за выбранный период
                ! Используется фактический курс платежа.

                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/17.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Сумма всех платежей, поступивших за выбранный период минус себестоимость платежей
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/18.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Недогруз считается по заказам клиента за выбранный период. Это разница между созданными (проведенными) заказами клиента и тем, что зарезервировано под него
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/19.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Планируемая сумма отгрузок, согласно документам Плана продаж (Планирование), за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/20.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Планируемая сумма платежей, согласно документам Плана продаж (Планирование), за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/21.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Статистика в разрезе Брендов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/22.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                    Статистика в разрезе Отделов за выбранный период
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/23.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

                <Typography variant="subtitle1" gutterBottom>
                   План поставок по Брендам
                </Typography>
                <div >
                    <img src="http://stat.obkgroup.com/static/images/24.png" alt="" style={{width: '150px'}}/>
                </div>
                <br/>

            </Paper>
        </div>

    );
};

export default withStyles(styles)(Help);
