import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const defaultProps = {
    title: 'Статистика'
};

const Header = (props) => {
    const classes = useStyles();

    const varLink = (props.title !== 'Help') ? (
        <Link to={'/help'}><Button
            style={{color: 'white', textDecoration:'none'}}
            color="inherit">Help</Button></Link>)
        : (
            <Link to={'/'}><Button
                style={{color: 'white', textDecoration:'none'}}
                color="inherit">Статистика</Button></Link>
        );
    return (
        <AppBar position="static">
            <Toolbar style={{minHeight: 20}}>
                <Typography variant="h6" className={classes.title}>
                    {props.title}
                </Typography>
                {(props.isAuth) &&
                    <React.Fragment>
                        { varLink }
                        <Button
                            onClick={() => {
                                window.localStorage.clear();
                                window.setTimeout(() => {
                                    window.location.reload(false);
                                }, 500);
                            }}
                            color="inherit"
                        >
                            Reload
                        </Button>
                        <Button
                            onClick={props.clearCacheHandler} color="inherit">
                            Сбросить Кэш
                        </Button>                        
                        <Button
                            onClick={props.logoutHandler} color="inherit">
                            Выход
                        </Button>
                    </React.Fragment>
                }
            </Toolbar>
        </AppBar>
    )
};

Header.defaultProps = defaultProps;

export default Header;


