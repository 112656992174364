export default class ApiService {
    _userApi = `/stat/`;
    _auth = `/stat/auth/`;

    async auth(url, opts) {
        const res = await fetch(`${this._auth}${url}`, {method: 'post', body: JSON.stringify(opts), headers: {'Content-Type': 'application/json'}, credentials: 'include'});
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}` +
                `, received ${res.status}`)
        }
        return await res.json();
    };

    login = async(opts) => {
        const res = await this.auth('login', opts);
        return res;
    };

    async authCheck(url, bearer) {
        const res = await fetch(`${this._auth}${url}`, {headers: {'Content-Type': 'application/json', Authorization: bearer}, credentials: 'include'});
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}` +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    getStatus = async() => {
        const bearer = this.getLocalToken();
        const res = await this.authCheck('check', bearer);
        return res;
    };

    getLocalToken = () => {
        if (window.localStorage.getItem('statAuthToken')) {
            return `Bearer ${window.localStorage.statAuthToken}`;
        }

        console.log('Token is missing');
        throw new Error('Token is missing');
    };

    async resource(url, opts) {
        const bearer = this.getLocalToken();
        const res = await fetch(
            `${this._userApi}${url}`,
            {
                method: 'post',
                body: JSON.stringify(opts),
                headers: {'Content-Type': 'application/json', Authorization: bearer},
                credentials: 'include'
            }
        );

        if (!res.ok) {
            throw new Error(`Could not fetch ${url}` +
                `, received ${res.status}`)
        }

        return await res.json();
    };

    getRes = async(opts) => {
        //const res = await this.resource('turnover', opts);
        const res = await this.resource('simple', opts);
        return res;
    };

    getResFull = async(opts) => {
        const res = await this.resource('full', opts);
        return res;
    };

    sendEmail = async(opts) => {
        const res = await this.resource('send', opts);
        return res;
    };

    clearCache = async() => {
        const opts = {}
        const res = await this.resource('clear', opts);
        return res;
    };    
}
