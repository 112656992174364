import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import {Calendar, Views, momentLocalizer} from 'react-big-calendar';
//import localizer from 'react-big-calendar/lib/localizers/moment';
import moment from 'moment';
import 'moment/locale/ru';
//import events from './events';
import Box from '@material-ui/core/Box';

import 'react-big-calendar/lib/sass/styles.scss';
import './calender.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

moment.locale("ru");
moment.lang('ru');
const localizer = momentLocalizer(moment);

const styles = theme => ({
    table: {

    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const data = [];

const messages = {
  allDay: 'Весь день',
  previous: '<',
  next: '>',
  today: 'Сегодня',
  month: 'Месяц',
  week: 'Неделя',
  day: 'День',
  agenda: 'Agenda',
  date: 'Дата',
  time: 'Время',
  event: 'СОбытие',
  showMore: total => `+ Еще (${total})`
};

class Calender extends Component {
    constructor(props) {
        super();
        this.state = {
            data: props.data,
            open: props.open
        }
    }

    render(){
        const { classes } = this.props;
        const {data} = this.state;

        const events = data.map((e, k) => {
            const date = Date.parse(e.date);
            return (
                {
                    id: e.id,
                    title: `${e.brand} - €${e.amount}`,
                    brand: e.brand,
                    amount: e.amount,
                    allDay: true,
                    start: date,
                    end: date
                }
            );
        });

        //console.log("events", events);

        return(
            <Dialog fullScreen open={this.state.open} onClose={this.props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Поставка
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box mb={3}>
                </Box>
                <Calendar
                    localizer={localizer}
                    defaultView={Views.MONTH}
                    views={{ month: true }}
                    culture='ru'
                    messages={messages}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    titleAccessor={(e) => {return (
                        <React.Fragment>
                            Бренд: {e.brand}
                            <br />
                            Сумма: € {tSep(e.amount)}
                        </React.Fragment>
                        )}
                    }
                />
            </Dialog>
        );
    }
}

function tSep(num) {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numParts.join(".");
}

export default withStyles(styles)(Calender);